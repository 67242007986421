import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './products.module.sass';

const Products = ({ children, listClassName }) => {
  return (
    <section className={styles.section}>
      <div className="container">
        <ul className={cx(styles.list, listClassName)}>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              className: cx(styles.listItem, child.props?.className),
            });
          })}
        </ul>
      </div>
    </section>
  );
};

Products.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  listClassName: PropTypes.string,
};

Products.defaultProps = {
  listClassName: '',
};

export default Products;
