import React from 'react';
import cx from 'classnames';

import styles from './contact.module.sass';
import { supportEmail, supportPhone, forgivenessSupport, collectionsEmail, loanservicingEmail, ChangeOfOwnership, ppsEmail, sbaEmail } from '../../../utils/constants';
import Layout from '../../../components/layout';
import SEO from '../../../components/common/seo';
import Banner from '../../../components/blocks/banner';
import Faq from '../../../components/blocks/faq';
import Products from '../../../components/blocks/products';

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title="Contact Us"
        description={`You can reach our team at ${supportPhone} or ${supportEmail} if you have questions about outstanding loans.`}
        urlPath="/support/contact/"
      />
      <Banner>
        <h1 className={cx('h2', styles.heading)}>Contact Us</h1>
      </Banner>
      <Products listClassName={styles.list}>
        <li className={styles.item}>
          <h2 className="h3">Kabbage Loans:</h2>
          <p>
            <b>Customer Service:</b>{' '}
            <a href={`tel:${supportPhone}`}>{supportPhone}</a>
          </p>
          <p>
            <b>Collections:</b> <a href="tel:855-747-2513">855-747-2513</a>
          </p>
          <p>
            Hours: Monday - Friday 7:00 to 3:30 PST
          </p>
          <p>
            <b>Email:</b> <a href={`mailto:${ppsEmail}`}>{ppsEmail}</a> or <a href={`mailto:${loanservicingEmail}`}>{loanservicingEmail}</a>
          </p>
        </li>
        <li className={styles.item}>
          <h2 className="h3">Bankruptcy Notices, Trustee Payments and all other correspondence:</h2>
          <p>
          <b>Mail to:</b>
          </p>
          <p>
          KServicing
          <br />
          3370 N. Hayden Road #123
          <br />
          PMB 681
          <br />
          Scottsdale, AZ 85251
          </p>
          {/* <p>
            Loan Servicing: <a href={`mailto:${loanservicingEmail}`}>{loanservicingEmail}</a>
          </p> */}
          {/* <p>
          Change of Ownership (business sale/merger inquiries only): <a href={`mailto:${ChangeOfOwnership}`}>{ChangeOfOwnership}</a>
          </p> */}
        </li>
        <li className={styles.item}>
          <h2 className="h3">Payments for existing Kabbage loans:</h2>
          <p>
          <b>Mail Payments to:</b>
          </p>
          <p>
          Payment Processing Services LLC
            <br />
            Attn: Payment Processing Services LLC
            <br />
            PO Box 16190
            <br />
            Phoenix, AZ 85011
          </p>
          <p>
          <b>Online Payments:</b>
          <br />
          <a
              href="https://www.evokepay.com/pps/login"
              target="_blank"
              rel="noreferrer"
            >
              https://www.evokepay.com/pps/login
            </a>
          </p>
        </li>
        <li className={styles.item}>
          <h2 className="h3">UCC Termination Requests:</h2>
          <p>
            <b>Email:</b> <a href={`mailto:${ppsEmail}`}>{ppsEmail}</a> or <a href={`mailto:${loanservicingEmail}`}>{loanservicingEmail}</a>
          </p>
        </li>
        <li className={styles.item}>
          <h2 className="h3">PPP Loans:</h2>
          <p>
          If you have a PPP loan, your loan servicing was transferred on April 18, 2023 to a servicer other than Payment Processing LLC.
          </p>
          <p>
          Contact the SBA to confirm your new loan servicer at <a href={`mailto:${sbaEmail}`}>{sbaEmail}</a>.
          </p>
          {/* <p>
            Loan Servicing: <a href={`mailto:${loanservicingEmail}`}>{loanservicingEmail}</a>
          </p> */}
          {/* <p>
          Change of Ownership (business sale/merger inquiries only): <a href={`mailto:${ChangeOfOwnership}`}>{ChangeOfOwnership}</a>
          </p> */}
        </li>
      </Products>
      {/* <Faq className={styles.faq} /> */}
    </Layout>
  );
};

export default ContactPage;
