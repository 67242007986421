import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './faq.module.sass';
import faq from './data';

const Faq = ({ className }) => {
  const [isExpanded, setIsExpanded] = useState(Array(faq.length).fill(false));

  const handleClick = (index) => {
    setIsExpanded((state) => {
      const newState = [...state];
      newState[index] = !state[index];
      return newState;
    });
  };

  return (
    <section className={styles.section}>
      <div className={cx('container', className)}>
        <div className={styles.card}>
          <h2 className={cx('h3', styles.heading)}>
            Frequently asked questions
          </h2>
          <div className={styles.content}>
            <ul>
              {faq.map(({ question, answer }, index) => {
                return (
                  // list is static, so can use index as key for now
                  // eslint-disable-next-line react/no-array-index-key
                  <li className={styles.question} key={index}>
                    <button
                      className="text-link"
                      type="button"
                      onClick={() => handleClick(index)}
                    >
                      {question}
                    </button>
                    <div
                      className={cx(styles.answer, {
                        [styles.answerExpanded]: isExpanded[index],
                      })}
                    >
                      {answer}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

Faq.propTypes = {
  className: PropTypes.string,
};

Faq.defaultProps = {
  className: '',
};

export default Faq;
