import React from 'react';
import { Link } from 'gatsby';

import constants from '../../../utils/constants';

const data = [
  {
    question: <p>When will I receive my forgiveness decision?</p>,
    answer: (
      <p>
	KServicing has 60 days from receipt of a complete loan forgiveness application to issue a 	
	recommendation to the SBA, and the SBA is required to issue a decision within 90 days after 
	the lender issues its recommendation to the SBA.
      </p>
    ),
  },
 
  {
    question: <p>Is KServicing currently originating new PPP loan(s)?</p>,
    answer: (
      <p>
        At this time, we are focused on servicing existing PPP loans. 
        SBA is currently only offering PPP loans originated by participating 
        community financial institutions.
      </p>
    ),
  },
  {
    question: <p>How and when can I apply for loan forgiveness?</p>,
    answer: (
      <p>
        A borrower can apply for forgiveness once all loan proceeds for which 
        the borrower is requesting forgiveness have been used. Borrowers can apply 
        for forgiveness any time up to the maturity date of the loan. If borrowers 
        do not apply for forgiveness within ten months after the end of the maximum
        loan forgiveness covered period (24 weeks from the loan disbursement date),
        then PPP loan payments are no longer deferred, and borrowers must begin making
        loan payments to their PPP lender.
      </p>
    ),
  },
  {
    question: <p>I don’t see where to apply for forgiveness with KServicing or Kabbage.</p>,
    answer: (
      <p>
        Borrowers can apply for forgiveness at this {' '}
        <a
          href={`https://kservicingforgiveness.biz2x.com/login`}
          target="_blank"
          rel="noreferrer"
        >
          link
        </a> 
        .
      </p>
    ),
  },
  {
    question: <p>What if my 10-month deferral period has passed? 
    Do I have to begin making loan repayments even though I’m 
    unable to apply for forgiveness through KServicing at this time? </p>,
    answer: (
      <p>
        If you applied for forgiveness during the covered period plus ten months, 
        loan repayments are deferred until you receive a decision on your loan forgiveness
        application.  Otherwise loan payments are due as indicated. Please email 
        pppforgiveness@kservicing.com with any additional questions.
      </p>
    ),
  },
  {
    question: (
      <p>What documents will I need to provide for you to process my forgiveness application? </p>
    ),
    answer: (
      <p>
        Borrowers must provide documentation to support forgiveness-eligible expenses.
        Borrowers may not be required to submit all of these documents, 
        depending on whether they qualify to use the SBA’s forgiveness application 
        Form EZ or Form S. In addition, as provided for in the December 27, 2020 legislation, 
        borrowers with loans under $150,000 who qualify to use the new Form 3508S may not need 
        to submit any supporting documentation. Please review the instructions to the forgiveness 
        applications for more information on which documentation you may need to submit or visit 
        {' '} 
        <a
          href={`https://${constants.domains.sba}`}
          target="_blank"
          rel="noreferrer"
        >
          SBA.gov
        </a>
         for more information.
      </p>
    ),
  },
  {
    question: <p>Why is my PPP loan information public?</p>,
    answer: (
      <p>
        K Servicing only uses your information as required or permitted by the SBA and the
        PPP program. In November, a federal court ordered the SBA to release certain SBA loan data.  
        A link to the SBA press release can be found {' '}
        <a
          href={`https://www.sba.gov/about-sba/sba-newsroom/press-releases-media-advisories/sba-and-treasury-announce-release-ppp-loan-data`}
          target="_blank"
          rel="noreferrer"
        >
          here
        </a> 
        .
      </p>
    ),
  },
  {
    question: <p>Who should borrowers contact with forgiveness questions?</p>,
    answer: (
      <p>
        For forgiveness questions, please email {' '}
        <a href={`mailto:${constants.forgivenessSupport}`}>
          {constants.forgivenessSupport}
        </a>
      </p>
    ),
  },
];

export default data;
